var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-profile", class: { grey: _vm.admin } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "profile-form",
          attrs: {
            "auto-complete": "on",
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
            disabled: _vm.loading,
          },
        },
        [
          _vm.admin
            ? _c(
                "el-form-item",
                { attrs: { label: "Account Email", prop: "email" } },
                [_vm._v(" " + _vm._s(_vm.user.email) + " ")]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Name", prop: "name" } },
            [
              _c("el-input", {
                attrs: { name: "name", type: "text" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mobile", prop: "mobile" } },
            [
              _c("el-input", {
                attrs: { name: "mobile", type: "text" },
                model: {
                  value: _vm.form.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobile", $$v)
                  },
                  expression: "form.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Contact email", prop: "contact_email" } },
            [
              _c("el-input", {
                attrs: { name: "contact_email", type: "text" },
                model: {
                  value: _vm.form.contact_email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contact_email", $$v)
                  },
                  expression: "form.contact_email",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm.loading
                    ? _c("i", { staticClass: "el-icon-loading" })
                    : _c("span", [_vm._v("Save")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.admin
        ? [
            !_vm.reset_password
              ? _c("p", { staticClass: "info" }, [
                  _vm._v(" Set a new temporary password for this user. "),
                ])
              : _vm._e(),
            _vm.reset_password === true && _vm.temp_password
              ? _c("p", { staticClass: "info" }, [
                  _c("i", {
                    staticClass: "el-icon-circle-check",
                    staticStyle: { color: "green" },
                  }),
                  _vm._v(" Temporary password for this user is: "),
                  _c("b", { staticClass: "password" }, [
                    _vm._v(_vm._s(_vm.temp_password)),
                  ]),
                  _vm._v(
                    " (Copy this password now, it won't be shown again.) "
                  ),
                ])
              : _vm._e(),
            _vm.reset_password === true && !_vm.temp_password
              ? _c("p", { staticClass: "info" }, [
                  _c("i", {
                    staticClass: "el-icon-circle-check",
                    staticStyle: { color: "green" },
                  }),
                  _vm._v(
                    " Temporary password has been sent to this user's contact email. "
                  ),
                ])
              : _vm._e(),
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, xl: 12 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pwd-button",
                        attrs: {
                          disabled: _vm.reset_password === false,
                          loading: _vm.reset_password === false,
                          type: "primary",
                        },
                        on: { click: () => _vm.handleResetPassword(true) },
                      },
                      [_vm._v(" Reset password & view ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 24, xl: 12 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pwd-button",
                        attrs: {
                          disabled:
                            _vm.reset_password === false ||
                            !_vm.user.contact_email,
                          loading: _vm.reset_password === false,
                          type: "primary",
                        },
                        on: { click: () => _vm.handleResetPassword(false) },
                      },
                      [_vm._v(" Reset password & send email ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }