<template>
    <div class="user-profile" :class="{grey: admin}">
        <el-form
            ref="form"
            class="profile-form"
            auto-complete="on"
            :model="form"
            :rules="rules"
            label-position="left"
            label-width="150px"
            :disabled="loading"
        >
            <el-form-item v-if="admin" label="Account Email" prop="email">
                {{ user.email }}
            </el-form-item>
            <el-form-item label="Name" prop="name">
                <el-input v-model="form.name" name="name" type="text" />
            </el-form-item>
            <el-form-item label="Mobile" prop="mobile">
                <el-input v-model="form.mobile" name="mobile" type="text" />
            </el-form-item>
            <el-form-item label="Contact email" prop="contact_email">
                <el-input
                    v-model="form.contact_email"
                    name="contact_email"
                    type="text"
                />
            </el-form-item>
            <div class="form-footer">
                <el-button :disabled="loading" @click="handleSave">
                    <i v-if="loading" class="el-icon-loading" />
                    <span v-else>Save</span>
                </el-button>
            </div>
        </el-form>

        <template v-if="admin">
            <p v-if="!reset_password" class="info">
                Set a new temporary password for this user.
            </p>
            <p v-if="reset_password === true && temp_password" class="info">
                <i style="color: green" class="el-icon-circle-check" />
                Temporary password for this user is:
                <b class="password">{{ temp_password }}</b>
                (Copy this password now, it won't be shown again.)
            </p>
            <p v-if="reset_password === true && !temp_password" class="info">
                <i style="color: green" class="el-icon-circle-check" />
                Temporary password has been sent to this user's contact email.
            </p>

            <el-row :gutter="10">
                <el-col :xs="24" :xl="12">
                    <el-button
                        class="pwd-button"
                        :disabled="reset_password === false"
                        :loading="reset_password === false"
                        type="primary"
                        @click="() => handleResetPassword(true)"
                    >
                        Reset password &amp; view
                    </el-button>
                </el-col>
                <el-col :xs="24" :xl="12">
                    <el-button
                        class="pwd-button"
                        :disabled="
                            reset_password === false || !user.contact_email
                        "
                        :loading="reset_password === false"
                        type="primary"
                        @click="() => handleResetPassword(false)"
                    >
                        Reset password &amp; send email
                    </el-button>
                </el-col>
            </el-row>
        </template>
    </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import {isValidEmail} from '@/utils/validate';
import axios from 'axios';

export default {
    name: 'user-profile',
    components: {},
    props: {
        user: {
            type: Object,
            required: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
        showAccountEmail: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const validateEmail = (rule, value, callback) => {
            // check if a valid email address
            if (!isValidEmail(value)) {
                callback(new Error('Invalid email address'));
                return;
            }
            callback();
        };
        return {
            form: {
                name: '',
                mobile: '',
                contact_email: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: 'User name is required',
                    },
                ],
                mobile: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: 'Mobile number is required',
                    },
                ],
                contact_email: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateEmail,
                    },
                ],
            },
            reset_password: null,
            temp_password: null,
            loading: false,
        };
    },
    computed: {},
    watch: {
        user: {
            handler(data) {
                this.form.name = data.name;
                this.form.mobile = data.mobile;
                this.form.contact_email = data.contact_email || data.email;
            },
            immediate: true,
        },
    },
    methods: {
        async handleSave() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    try {
                        await this.$fire.doc(`users/${this.user.id}`).update({
                            name: this.form.name,
                            mobile: this.form.mobile,
                            contact_email: this.form.contact_email,
                            status: null,
                        });
                        this.$emit('saved', this.user);
                    } catch (e) {
                        console.log(e);
                    } finally {
                        this.loading = false;
                    }
                }
            });
        },
        handleResetPassword(display_password) {
            this.reset_password = false;
            let postdata = {email: this.user.email};
            if (display_password) {
                postdata['display_password'] = 'true';
            }
            axios
                .post(
                    `${process.env.VUE_APP_BASE_URI}/user/password/reset`,
                    postdata
                )
                .then((response) => {
                    this.temp_password = response.data.data?.password ?? null; // null if email was sent
                    this.reset_password = true;
                })
                .catch((e) => {
                    this.reset_password = null;
                    this.$notify.error({
                        title: 'Error',
                        message: e.message,
                    });
                    Sentry.captureException(e);
                });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-profile {
    background: white;
    padding: 20px;
    border-radius: 10px;
    &.grey {
        background: $grey;
    }
}
.form-footer {
    text-align: right;
}

.el-button {
    color: white;
    background-color: $menuBg;

    &:hover {
        background-color: $menuHover;
    }
}
.info {
    font-size: 14px;
    color: #808080;
    .password {
        color: #404040;
        background-color: #e0e0e0;
        padding: 2px 5px;
        border-radius: 3px;
    }
}
.pwd-button {
    width: 100%;
    margin-bottom: 5px;
    background-color: $purple-rain;
    &:hover {
        background-color: #a642fe;
    }
}
</style>
